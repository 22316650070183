import {ModelBase} from './abstract/model-base';
import {SettingOption} from './setting-option.class';
import {OrganisationSetting} from './organisation-setting.class';
import {ShopSetting} from './shop-setting.class';
import {PersonalisationSetting} from './personalisation-setting';

export class Setting extends ModelBase {
    public id;
    public type;
    public entity: string;
    public isEditable;
    public isAdvanced;
    public isSecret;
    public isRequired = false;
    public labelTranslate: boolean;
    public defaultValue;
    public defaultSettingOptionId;
    public settingOptions: SettingOption[];
    public settingTriggers: string[];
    public settingGroupId: string;
    public organisationSetting: OrganisationSetting;
    public shopSetting: ShopSetting;
    public personalisationSetting: PersonalisationSetting;
    public createdAt;

    constructor() {
        super();
        super.setRoutes({
            getMany: 'v1.0/setting',
        });
    }

    mapModel(data: any) {
        const model = new Setting();
        model.id = data.id;
        model.type = data.type;
        model.entity = data.entity;
        model.isEditable = data.isEditable;
        model.isAdvanced = data.isAdvanced;
        model.isSecret = data.isSecret;
        model.defaultValue = data.defaultValue;
        model.defaultSettingOptionId = data.defaultSettingOptionId;
        model.settingGroupId = data.settingGroupId;
        model.settingTriggers = data.settingTriggers;
        model.settingOptions = data.settingOptions ? data.settingOptions.map(settingOption => new SettingOption().mapModel(settingOption)) : [];
        model.createdAt = data.createdAt;
        model.labelTranslate = data.labelTranslate ?? true;

        if (data.entity === 'ORGANISATION') {
            if (data.organisationSetting) {
                model.organisationSetting = new OrganisationSetting().mapModel(data.organisationSetting);
            } else {
                model.organisationSetting = new OrganisationSetting();
                model.organisationSetting.settingId = model.id;
                model.organisationSetting.value = model.defaultValue;
                model.organisationSetting.settingOptionId = model.defaultSettingOptionId;
            }
        }

        if (data.entity === 'SHOP') {
            if (data.shopSetting) {
                model.shopSetting = new ShopSetting().mapModel(data.shopSetting);
            } else {
                model.shopSetting = new ShopSetting();
                model.shopSetting.settingId = model.id;
                model.shopSetting.value = model.defaultValue;
                model.shopSetting.settingOptionId = model.defaultSettingOptionId;
            }
        }

        if (data.entity === 'PERSONALISATION') {
            if (data.personalisationSetting) {
                model.personalisationSetting = new PersonalisationSetting().mapModel(data.personalisationSetting);
            } else {
                model.personalisationSetting = new PersonalisationSetting();
                model.personalisationSetting.settingId = model.id;
                model.personalisationSetting.value = model.defaultValue;
                model.personalisationSetting.settingOptionId = model.defaultSettingOptionId;
            }

            if (model.type === 'TRANSLATION') {
                model.personalisationSetting.valueTranslation = model.personalisationSetting.value;
            }
        }

        return model;
    }
}
