import {mapSubscriptionType, SubscriptionType} from './subscription-type.class';

export class ShopSubscriptionType {
    constructor(public subscriptionTypeId: string,
                public subscriptionType?: SubscriptionType) {
    }
}

export function mapShopSubscriptionType(item: any): ShopSubscriptionType {
    return new ShopSubscriptionType(
        item.subscriptionTypeId,
        item.subscriptionType ? mapSubscriptionType(item.subscriptionType) : null
    );
}

