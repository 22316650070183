import {mapShopEvent, ShopEvent} from './shop-event.class';
import {mapShopSubscriptionType, ShopSubscriptionType} from './shop-subscription-type.class';

export class ShopPage {
    constructor(
        public id: string = null,
        public shopId: string = null,
        public menuName: string,
        public pageName: string,
        public description: string,
        public menuNameTranslation: any,
        public pageNameTranslation: any,
        public descriptionTranslation: any,
        public shopEvents: ShopEvent[] = [],
        public shopSubscriptionTypes: ShopSubscriptionType[] = [],
        public isHidden: boolean,
        public order: number,
        public isResellPage: boolean
    ) {

    }

    public static empty(shopId: string): ShopPage {
        return new ShopPage(null, shopId, null, null, null, null, null, null, [], [], false, 0, false);
    }
}

export function mapShopPage(item: any) {
    return new ShopPage(
        item.id,
        item.shopId,
        item.menuName,
        item.pageName,
        item.description,
        item.menuNameTranslation,
        item.pageNameTranslation,
        item.descriptionTranslation,
        item.shopEvents ? item.shopEvents.map((shopEvent) => shopEvent ? mapShopEvent(shopEvent) : null) : [],
        item.shopSubscriptionTypes ? item.shopSubscriptionTypes.map(shopSubscriptionType => shopSubscriptionType ? mapShopSubscriptionType(shopSubscriptionType) : null) : [],
        item.isHidden,
        item.order,
        item.isResellPage
    );
}


