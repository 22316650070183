import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {mapShop, Shop} from '../../models/shop.class';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {mapShopPage, ShopPage} from '../../models/shop-page';
import {mapShopStyle, ShopStyle} from '../../models/shop-style';
import {ShopSetting} from '../../models/shop-setting.class';
import {BusEvent, BusService} from '../../../message-bus/bus.service';
import {NotificationService} from '../notification-service/notification.service';

@Injectable({
    providedIn: 'root'
})
export class ShopService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient, private bus: BusService, private readonly notificationService: NotificationService) {
        this.bus.on('SHOP_LIST.MARK_AS_PRIMARY', (e: BusEvent) => {
            this.setShopIsPrimary(e.details.shopId).subscribe({
                next: () => {
                    this.bus.emit({
                      id: 'LIST_PAGE.REFRESH'
                    });
                    this.notificationService.showTranslatedNotification('success', 'shop', 'marked_as_primary');
                }
            });
        });

        this.bus.on('SHOP_LIST.OPEN_SHOP', (e: BusEvent) => {
            window.open(e.details.url, '_blank');
        });
    }

    getShop(shopId: string, params?: HttpParams): Observable<Shop> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Shop>(this.baseUrl + `/v1.0/shop/${shopId}`, config)
            .pipe(
                map(item => {
                    return mapShop(item);
                })
            );
    }

    getShops(params?: HttpParams): Observable<Shop[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Shop[]>(this.baseUrl + `/v1.0/shop`, config)
            .pipe(
                map(shops => {
                    return shops.map(item => mapShop(item));
                })
            );
    }

    getSimpleShopsPaginated(params?: HttpParams): Observable<HttpResponse<Shop[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Shop[]>(this.baseUrl + `/v1.0/shop/simple`, {
            ...config,
            observe: 'response'
        }).pipe(
            map(response => {
                const body = response.body.map(shop => {
                    return mapShop(shop);
                });

                return response.clone({body});
            })
        );
    }

    postShop(body: any, params?: HttpParams): Observable<Shop> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<Shop>(this.baseUrl + `/v1.0/shop`, body, config)
            .pipe(
                map(item => {
                    return mapShop(item);
                })
            );
    }

    patchShop(shopId: string, body: any, params?: HttpParams): Observable<Shop> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<Shop>(this.baseUrl + `/v1.0/shop/${shopId}`, body, config)
            .pipe(
                map(item => {
                    return mapShop(item);
                })
            );
    }

    postShopPage(shopPage: ShopPage, params?: HttpParams): Observable<ShopPage> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<ShopPage>(this.baseUrl + `/v1.0/shop/page`, shopPage, config)
            .pipe(
                map(item => {
                    return mapShopPage(item);
                })
            );
    }

    patchShopPage(shopPageId: string, shopPage: ShopPage, params?: HttpParams): Observable<ShopPage> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<ShopPage>(this.baseUrl + `/v1.0/shop/page/${shopPageId}`, shopPage, config)
            .pipe(
                map(item => {
                    return mapShopPage(item);
                })
            );
    }

    deleteShopPage(shopPageId): Observable<any> {
        return this.http.delete<ShopPage>(this.baseUrl + `/v1.0/shop/page/${shopPageId}`, this.config);
    }

    patchShopStyle(shopStyleId: string, shopStyle: ShopStyle, params?: HttpParams): Observable<ShopStyle> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<ShopStyle>(this.baseUrl + `/v1.0/shop/style/${shopStyleId}`, shopStyle, config)
            .pipe(
                map(item => {
                    return mapShopStyle(item);
                })
            );
    }

    setShopIsPrimary(shopId: string) {
        const config = Object.assign({}, this.config);
        config.params = new HttpParams().set('depth', '2');

        const body = {
            shopId: shopId
        };

        return this.http.post<Shop>(this.baseUrl + `/v1.0/shop/primary`, body, config)
            .pipe(
                map(item => {
                    return mapShop(item);
                })
            );
    }

    postShopSettings(shopId: string, shopSettings: ShopSetting[], params?: HttpParams): Observable<ShopSetting[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        shopSettings.forEach(shopSetting => shopSetting.shopId = shopId);

        return this.http.post<ShopSetting[]>(this.baseUrl + `/v1.0/shop/${shopId}/setting/bulk`, shopSettings, config);
    }
}
