import {ModelBase} from './abstract/model-base';

export class OrganisationSetting extends ModelBase {
    public id;
    public settingId;
    public settingOptionId;
    public value;
    public createdAt;
    public updatedAt;


    constructor() {
        super();
        super.setRoutes({
                            postBulk: 'v1.0/organisation/:organisationId/setting/bulk',
                            post:     'v1.0/organisation/:organisationId/setting',
                            // getMany:  'v1.0/organisation/setting',
                            get:      'v1.0/organisation/:organisationId/setting/:id',
                            patch:    'v1.0/organisation/:organisationId/setting/:id',
                            delete:   'v1.0/organisation/:organisationId/setting/:id'
                        });
    }

    mapModel(data: any) {
        const model           = new OrganisationSetting();
        model.id              = data.id;
        model.settingId       = data.settingId;
        model.settingOptionId = data.settingOptionId;
        model.value           = data.value;
        model.createdAt       = data.createdAt;
        model.updatedAt       = data.updatedAt;

        return model;
    }
}

