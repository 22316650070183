import {mapShopPage, ShopPage} from './shop-page';
import {mapShopStyle, ShopStyle} from './shop-style';
import {ModelBase} from './abstract/model-base';
import {environment} from '../../../environments/environment';
import {Setting} from './filter-setting.class';
import {mapShopTracker, ShopTracker} from './shop-tracker.class';

export class Shop extends ModelBase {
    public id: string = null;
    public isPrimary = false;
    public name: string = null;
    public slug: string = null;
    public accountLabel: string = null;
    public accountLabelTranslation: any = null;
    public footerLeft: string = null;
    public footerLeftTranslation: any = null;
    public footerMiddle: string = null;
    public footerMiddleTranslation: any = null;
    public footerRight: string = null;
    public footerRightTranslation: any = null;
    public loginIntroduction: string = null;
    public loginIntroductionTranslation: any = null;
    public domain: string = environment.SEATED_SHOP_URL;
    public shopPages: ShopPage[] = [];
    public languages: string[] = [];
    public shopStyle: ShopStyle = null;
    public trackers: ShopTracker[] = [];
    public analytics?: any = null;
    public footer?: any[] = [];
    public shopSettings: Setting[] = [];
    public url?: string;
    public features: string[] = [];

    constructor() {
        super();
        super.setRoutes({
            post: 'v1.0/shop',
            getMany: 'v1.0/shop',
            get: 'v1.0/shop/:id',
            patch: 'v1.0/shop/:id',
            delete: 'v1.0/shop/:id'
        });
    }

    mapModel(data: any): Shop {
        const model = new Shop();
        model.id = data.id;
        model.isPrimary = data.isPrimary;
        model.name = data.name;
        model.slug = data.slug;
        model.accountLabel = data.accountLabel;
        model.accountLabelTranslation = data.accountLabelTranslation;
        model.footerLeft = data.footerLeft;
        model.footerLeftTranslation = data.footerLeftTranslation;
        model.footerMiddle = data.footerMiddle;
        model.footerMiddleTranslation = data.footerMiddleTranslation;
        model.footerRight = data.footerRight;
        model.footerRightTranslation = data.footerRightTranslation;
        model.loginIntroduction = data.loginIntroduction;
        model.loginIntroductionTranslation = data.loginIntroductionTranslation;
        model.domain = data.domain;
        model.languages = data.languages;
        model.shopPages = data.shopPages ? data.shopPages.map(page => mapShopPage(page)) : [];
        model.shopStyle = data.shopStyle ? mapShopStyle(data.shopStyle) : null;
        model.trackers = data.trackers ? data.trackers.map(t => mapShopTracker(t)) : [];
        model.analytics = data.analytics ? data.analytics : null;
        model.shopSettings = data.shopSettings ? data.shopSettings.map(setting => new Setting().mapModel(setting)) : [];
        model.url = data.url;
        model.features = data.features;

        return model;
    }

    public getShopLink(): string {
        if (this.url) {
            return this.url;
        }

        if (this.domain && this.slug) {
            return `${this.domain}${this.slug}`;
        }

        return '';
    }
}

export function mapShop(item: any): Shop {
    return new Shop().mapModel(item);
}

export function mapShopForShopSettingsPage(item: any): Shop {
    item = JSON.parse(JSON.stringify(item));

    delete item.shopStyle;
    delete item.analytics;
    delete item.domain;
    delete item.id;
    delete item.isPrimary;

    return new Shop().mapModel(item);
}
