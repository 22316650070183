export class ShopStyle {
    constructor(
        public id: string,
        public shopId: string,
        public general: {
            backgroundImage: string,
            backgroundColor: string
            borderRadius: string
        },
        public header: {
            logoImage: string,
            backgroundImage: string,
            backgroundColor: string,
            textColor: string,
            border: string
        },
        public footer: {
            backgroundColor: string,
            textColor: string,
            border: string,
            logos: {
                id: string,
                name: string,
                logo: string,
                link: string,
                isMain: boolean,
                order: number
            }[]
        },
        public orderBar: {
            backgroundColor: string,
            textColor: string,
            border: string
        },
        public card: {
            backgroundColor: string,
            highlightBackgroundColor: string,
            textColor: string,
            border: string
        },
        public buttonPrimary: {
            backgroundColor: string,
            textColor: string,
            border: string
        },
        public buttonSecondary: {
            backgroundColor: string,
            textColor: string,
            border: string
        },
        public buttonTertiary: {
            textColor: string
        }) {
    }
}

export function mapShopStyle(item: any): ShopStyle {
    return new ShopStyle(
        item.id,
        item.shopId,
        item.general,
        item.header,
        item.footer,
        item.orderBar,
        item.card,
        item.buttonPrimary,
        item.buttonSecondary,
        item.buttonTertiary
    );
}
