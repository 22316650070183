export class SettingOption {
    public id;
    public settingId;
    public value;
    public createdAt;
    public label;

    mapModel(data: any) {
        const model = new SettingOption();
        model.id = data.id;
        model.settingId = data.settingId;
        model.value = data.value;
        model.createdAt = data.createdAt;
        model.label = model.settingId ? `Setting_Option.${model.settingId}.${model.value}` : model.value;

        return model;
    }
}

