export class ShopTracker {
    constructor(
        public uuid: string,
        public type: ShopTrackerType,
        public key: string) {
    }

    public static empty(type: ShopTrackerType): ShopTracker {
        return new ShopTracker(null, type, null);
    }
}

export function mapShopTracker(item: any): ShopTracker {
    return new ShopTracker(
        item.uuid,
        item.type,
        item.key
    );
}

export enum ShopTrackerType {
    GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
    FACEBOOK_PIXEL = 'FACEBOOK_PIXEL',
    GOOGLE_TAG_MANAGER = 'GOOGLE_TAG_MANAGER',
    BLUECONIC = 'BLUECONIC',
    CASHBACK_WORLD = 'CASHBACK_WORLD',
    FLASHTALKING = 'FLASHTALKING',
}
