import {Event, mapEvent} from './event.class';

export class ShopEvent {
    constructor(public eventId: string,
                public event?: Event) {
    }
}

export function mapShopEvent(item: any): ShopEvent {
    return new ShopEvent(
        item.eventId,
        item.event ? mapEvent(item.event) : null
    );
}

