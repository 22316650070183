import {ModelBase} from './abstract/model-base';

export class ShopSetting extends ModelBase {
    public id;
    public shopId: string;
    public settingId;
    public settingOptionId;
    public value;
    public createdAt;
    public updatedAt;

    constructor() {
        super();
        super.setRoutes({});
    }

    mapModel(data: any) {
        const model = new ShopSetting();
        model.id = data.id;
        model.shopId = data.shopId;
        model.settingId = data.settingId;
        model.settingOptionId = data.settingOptionId;
        model.value = data.value;
        model.createdAt = data.createdAt;
        model.updatedAt = data.updatedAt;

        return model;
    }
}

