import {ModelBase} from './abstract/model-base';
import {Translation} from './translation';

export class PersonalisationSetting extends ModelBase {
    public id: string;
    public technicalLinkId: string;
    public shopId: string;
    public settingOptionId: string;
    public settingId: string;
    public value: any;

    public valueTranslation: Translation;

    constructor() {
        super();
        super.setRoutes({
            postBulk: 'v2.0/personalisation/:shopId/setting',
            getMany: 'v2.0/personalisation/:shopId/setting'
        });
    }

    mapModel(data: any): PersonalisationSetting {
        const model = new PersonalisationSetting();

        model.id = data.Id;
        model.technicalLinkId = data.technicalLinkId;
        model.shopId = data.shopId;
        model.settingOptionId = data.settingOptionId;
        model.settingId = data.settingId;
        model.value = data.value;

        return model;
    }
}
